import { Component, HostListener, OnInit, TemplateRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { ClientService } from 'src/app/core/services/client.service';
import { SidebarService } from 'src/app/core/services/sidebar.service';
import { AuthState } from 'src/app/state/auth/auth.reducer';
import { BenefitEffects } from 'src/app/state/benefit/benefit.effects';
import { BenefitState } from 'src/app/state/benefit/benefit.reducer';
import * as CartActions from 'src/app/state/cart/cart.actions';
import { CartState } from 'src/app/state/cart/cart.reducer';
import { BenefitResponse } from 'src/models/benefit';
import { BenefitCategoryResponse } from 'src/models/benefit-category';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit {
  cart = [];
  showMobileCart = false;
  windowWidth = window.innerWidth;
  groupedBenefits: any = {};
  categories: BenefitCategoryResponse[] = [];
  standartCollapsed = false;
  unchangedCollapsed = false;
  boughtCollapsed = false;
  soldCollapsed = false;
  mainBenefitIds = [];
  calcResults: any;
  standart = [];
  unchanged = [];
  bought = [];
  modalVisible = false;
  sold = [];
  authUser = null;
  isCompleted = false;
  packagename :any;
  isSidebarVisible: boolean = false;

  constructor(
    private store: Store<{ cart: CartState, benefit: BenefitState, auth: AuthState }>,
    private authService: AuthService,
    private sidebarService: SidebarService,
    private clientService: ClientService,
    private translateService: TranslateService,
    public benefitEffects: BenefitEffects,
  ) {
    this.benefitEffects.getDataWithCalcError = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = window.innerWidth;
  }
  onModalClose() {
    this.modalVisible = false;
  }
  showLModal() {
    this.modalVisible = true;
  }

 
  ngOnInit() {

    this.sidebarService.isSidebarVisible$.subscribe((isVisible) => {
      this.isSidebarVisible = isVisible;
      console.log("Sidebar görünürlüğü: ", isVisible); 
    });

    this.store.select(state => state.auth.defaultLanguage).subscribe(res => {
      if (res) {
        const language = res.LanguageAbbreviations.toLowerCase();
        this.translateService.setDefaultLang(language);
      }
    });
    this.authUser = this.authService.getAuthUser();
    if (this.authUser.completed == "True") {
      this.isCompleted = true
    }
    this.isCompleted = localStorage.getItem('userCompleted') && localStorage.getItem('userCompleted') === 'True';
    this.store.select(state => state && state.benefit && state.benefit.data).subscribe(data => {
      this.calcResults = data && data.calcResults ? data.calcResults : null;
      this.populateSelectedBenefits();
      if (this.calcResults) {
        const standart = this.getBenefits(this.calcResults.groupResult.standart);
        const unchanged = this.getBenefits(this.calcResults.groupResult.unchanged);
        this.standart = standart.concat(unchanged);
        this.unchanged = unchanged;
        this.bought = this.getBenefits(this.calcResults.groupResult.bought);
        this.sold = this.getBenefits(this.calcResults.groupResult.sold);
      }
    });
    /* this.store.select(state => state && state.cart && state.cart.shoppingCart).subscribe(cart => {
      this.cart = cloneDeep(cart);
    });

    this.store.select(state => state && state.cart && state.benefit.mainBenefits).subscribe(benefits => {
      if (benefits) {
        this.mainBenefitIds = benefits.map(benefit => benefit.Id);
        this.store.dispatch(new CartActions.PopulateCartWithMainBenefits({ benefits }));
      } else {
        const user = this.authService.getAuthUser();
        this.store.dispatch(new BenefitActions.GetMainBenefits({ companyId: +user.companyId }));
      }
    });
    this.store.select(state => state && state.benefit && state.benefit.benefitCategories).subscribe(
      categories => {
        if (categories) {
          this.categories = cloneDeep(categories);
        } else {
          this.store.dispatch(new BenefitActions.GetBenefitCategories());
        }
      }
    ); */
    this.store.select('cart', 'showMobileCart').subscribe(showMobileCart => {
      this.showMobileCart = showMobileCart;
    });
  }

  get totalPrice() {
    return this.cart.reduce((acc, cur) => acc + +cur.Amount, 0);
  }

  deleteBenefitFromCart(benefitId) {
    this.store.dispatch(new CartActions.RemoveBenefitFromCart({ benefitId }));
  }

  cartVisible(): boolean {
    return this.windowWidth < 983 ? this.showMobileCart : true;
  }

  closeCart(): void {
    this.sidebarService.closeSidebar();
    // this.store.dispatch(new CartActions.ToggleMobileCart());
  }

  getCartItemsByCategoryId(categoryId: any, mainBenefits = false) {
    if (mainBenefits === true) {
      return this.cart.filter(c => this.mainBenefitIds.indexOf(c.Id) !== -1);
    }
    return this.cart.filter(x => x.BenefitCategoryId === categoryId);
  }
  getOthers(categoryId: any, other = false) {
    if (other === true) {
      const categoryIds = this.categories.map(x => x.Id);
      return this.cart.filter(c => categoryIds.indexOf(c.BenefitCategoryId) === -1 && this.mainBenefitIds.indexOf(c.Id) === -1);
    }
    return this.cart.filter(x => x.BenefitCategoryId === categoryId);
  }

  getAvailableCategories(): any[] {
    if (this.cart.length === 0 || this.categories.length === 0) {
      return [];
    }
    const benefitCategoryIds = this.cart.map((x: BenefitResponse) => x.BenefitCategoryId);
    return this.categories.filter(x => benefitCategoryIds.indexOf(x.Id) !== -1);
  }

  getBenefits(categories) {
    const benefits = [];
    const packageName = null
    categories.forEach(category => {
      category.Benefits.forEach(benefit => {
        
        benefits.push(benefit);
      });
    });
    return benefits;
  }

  getBenefitCount() {
    let total = 0;
    if (this.calcResults && this.calcResults.groupResult) {

      ['bought'].forEach(x => {
        total += this.getBenefits(this.calcResults.groupResult[x]).length;
      });
    }
    return total;
  }

  populateSelectedBenefits() {
    const ids = [];
    if (this.calcResults && this.calcResults.groupResult) {
      
      ['standart', 'unchanged', 'bought', 'sold'].forEach(x => {
        this.getBenefits(this.calcResults.groupResult[x]).forEach(b => {
          ids.push(b.Id);
        });
      });
    }
  }

  resetBenefits() {
    
      // this.loading = true;
      const authUser = this.authService.getAuthUser();
      this.clientService
        .restartUserBenefits(authUser.userId)
        .subscribe(data => {
          window.location.reload();
          /* this.calcResults = data && data.calcResults ? data.calcResults : null;
          this.populateSelectedBenefits();
          if (this.calcResults) {
            this.standart = this.getBenefits(this.calcResults.groupResult.standart);
            this.unchanged = this.getBenefits(this.calcResults.groupResult.unchanged);
            this.bought = this.getBenefits(this.calcResults.groupResult.bought);
            this.sold = this.getBenefits(this.calcResults.groupResult.sold);
          } */
          // this.loading = false;
        });
  }

  isHealthBenefit(type): boolean {
    return ['mainhealth', 'partnerhealth', 'childhealth'].indexOf(type) > -1;
  }
}