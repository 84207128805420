import { PageService } from "./../../../../core/services/page.service";
import {
  Component,
  HostListener,
  Input,
  OnInit,
  Inject,
  TemplateRef
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { cloneDeep } from "lodash";
import { ToastrService } from "ngx-toastr";
import {
  AppConfig,
  IAppConfig,
  APP_CONFIG
} from "src/app/core/services/app.config";
import { AuthService } from "src/app/core/services/auth.service";
import { ClientService } from "src/app/core/services/client.service";
import * as AuthActions from "src/app/state/auth/auth.actions";
import * as BenefitActions from "src/app/state/benefit/benefit.actions";
import { BenefitState } from "src/app/state/benefit/benefit.reducer";
import * as CartActions from "src/app/state/cart/cart.actions";
import { CartState } from "src/app/state/cart/cart.reducer";
import { AnnouncementResponse } from "src/models/announcement";
import { AuthUser } from "src/models/auth-user";
import { LanguageService } from "./../../../../core/services/language.service";
import { AuthState } from "src/app/state/auth/auth.reducer";
import { TranslateService } from "@ngx-translate/core";
import { NgbCarouselConfig, NgbCarousel } from "@ng-bootstrap/ng-bootstrap";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { SidebarService } from "src/app/core/services/sidebar.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  providers: [NgbCarouselConfig]
})
export class HeaderComponent implements OnInit {
  @Input() showFromSalary = true;
  @Input() showMenu=true;
  title: string;
  informationVisible = true;
  cart = [];
  public companyId: string;
  windowWidth = window.innerWidth;
  fromSalary = null;
  mobileMenuVisible = false;
  user: AuthUser = null;
  calcResults: any = null;
  flex = { Name: "", Amount: 0, Limit: "", Description: "" };
  companyLogo = "";
  model = new AnnouncementResponse();
  public loading = false;
  success: string;
  modalVisible = false;
  languageVisible = false;
  profileVisible=false;
  languages = [];
  selectedLanguage: any;
  helpLanguage: any;
  slides: any = null;
  helpVisible = false;
  isNotHaveContent = false;
  modalRef: BsModalRef;
  showNavigationIndicators = false;
  showNavigationArrows = true;
  checkHome:boolean=false;
  chekSurvey:boolean=false;
  authUser = null;
  isCompleted = false;

  constructor(
    private route: ActivatedRoute,
    private sidebarService: SidebarService,
    private store: Store<{
      cart: CartState;
      benefit: BenefitState;
      auth: AuthState;
    }>,
    private authService: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private clientService: ClientService,
    private languageService: LanguageService,
    private pageService: PageService,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private modalService: BsModalService,
    config: NgbCarouselConfig
  ) {
    config.showNavigationArrows = true;
    config.showNavigationIndicators = false;
    config.interval = 0;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.windowWidth = window.innerWidth;
  }

  toggleCart() {
    this.sidebarService.toggleSidebar();
  }
  
  ngOnInit() {
    this.user = this.authService.getAuthUser();
    this.languageService.getUserLanguage(this.user.userId).subscribe(res => { 
      //console.log(this.user)
      this.helpLanguage = res;
      this.getHelp();
      this.store.dispatch(
        new AuthActions.SetDefaultLanguage({ defaultLanguage: res })
      );
    });
    this.authUser = this.authService.getAuthUser();
    if (this.authUser.completed == "True") {
      this.isCompleted = true
    }
    this.isCompleted = localStorage.getItem('userCompleted') && localStorage.getItem('userCompleted') === 'True';

    // this.languageService.getLanguage(this.user['languageId']).subscribe((res:any)=>{
    //   this.store.dispatch(new AuthActions.SetDefaultLanguage({ defaultLanguage: res }));
    // }

    //)
    this.store
      .select(state => state.auth.defaultLanguage)
      .subscribe(language => {
        this.selectedLanguage = language;
      });
    this.languageService
      .getLanguageByCompanyId(this.user.companyId)
      .subscribe((res: any) => {
        this.languages = res;
      });
    this.companyLogo = AppConfig.storageUrl + "/" + this.user.companyLogo;
    this.store
      .select(state => state && state.benefit && state.benefit.data)
      .subscribe(data => {
        this.calcResults = data && data.calcResults ? data.calcResults : null;
        if (this.calcResults) {
          this.flex = cloneDeep(this.calcResults.calcResult.flex);
        }
      });

    if (!this.user.companyId) {
      this.authService.logout(true);
    }
    this.route.data.subscribe(data => {
      this.title = data.title || "";
    });
    /* this.store.select(state => state && state.cart && state.cart.shoppingCart).subscribe(cart => {
      this.cart = cart;
    }); */

    this.getAnnouncement();
    this.modalService.onHide.subscribe(e => {
      console.log("close", this.modalService.config.initialState);
    });
  }
 

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  onModalClose() {
    this.modalVisible = false;
    this.helpVisible = false;
  }
  showHelpModal() {
    this.helpVisible = true;
  }
  showLogoutModal() {
    this.modalVisible = true;
  }

  hideInformation(): void {
    this.informationVisible = false;
  }

  openCart(): void {
    this.store.dispatch(new CartActions.ToggleMobileCart());
  }

  transferSalary(salary: number) {
    if (!salary) {
      return;
    }
    this.store.dispatch(
      new CartActions.AddBenefitToCart({
        benefit: {
          id: Math.floor(Math.random() * 1000) + 10000,
          name: "Maaşımdan senCard'a",
          price: salary,
          categoryId: null,
          type: "from-salary",
          image: null
        }
      })
    );
  }

  toggleMobileMenu(): void {
    this.mobileMenuVisible = !this.mobileMenuVisible;
  }
  getAnnouncement() {
    this.loading = true;
    this.clientService.getAnnouncement(this.user.companyId).subscribe(data => {
      this.model = data as AnnouncementResponse;
      this.loading = false;
    });
  }
  goHome() {
    this.router.navigate(["/home"]);
  }

  getHelp() {
    this.loading = true;
    this.pageService
      .getHelpPages(
        this.user.companyId,
        "help",
        this.helpLanguage.LanguageAbbreviations
      )
      .subscribe(data => {
        this.slides = data;
        if (!this.slides || !this.slides.length) {
          this.isNotHaveContent = true;
        }
      });
  }

  benefitTextChanged(benefit) {
    // this.loading = true;
    if (!benefit.Const && !benefit.Amount) {
      benefit.Amount = 0;
    }
    if (benefit.Const && !benefit.Piece) {
      benefit.Piece = 0;
    }
    if (benefit.Limit && benefit.Amount > benefit.Limit) {
      if (benefit.Type === "flex") {
        // alert(benefit.Name + ' yan hakkının üst limitini aştınız.');
        this.toastr.error("", this.translate.instant("limitSencard", benefit), {
          toastClass: "ngx-toastr w-400",
          timeOut: 0
        });
      } else {
        alert(
          benefit.Name +
            " yan hakkının üst limiti " +
            benefit.Limit +
            " TL'dir."
        );
      }

      // this.loading = false;
    } else {
      const user = this.authService.getAuthUser();
      this.clientService
        .updateUserBenefit(user.userId, benefit, null)
        .subscribe(res => {
          this.toastr.success(
            "",
            this.flex.Amount == 0
              ? this.translate.instant("resetSenCard")
              : this.translate.instant("salaryToSencard", {
                  Amount: this.flex.Amount
                }),
            {
              toastClass: "ngx-toastr w-400"
            }
          );
          this.store.dispatch(
            new BenefitActions.GetData({
              userId: user.userId
            })
          );
          // this.loading = false;
        });
    }
  }

  getBenefitCount() {
    let total = 0;
    if (this.calcResults && this.calcResults.groupResult) {
      ["bought"].forEach(x => {
        total += this.getBenefits(this.calcResults.groupResult[x]).length;
      });
    }
    return total;
  }

  getBenefits(categories) {
    const benefits = [];
    categories.forEach(category => {
      category.Benefits.forEach(benefit => {
        benefits.push(benefit);
      });
    });
    return benefits;
  }

  toggleDropdown() {
    this.languageVisible = !this.languageVisible;
  }
  toggleProfileDropdown()
  {
    this.profileVisible = !this.profileVisible;
  }

  changeLanguage(language) {
    this.selectedLanguage = language;
    this.languageVisible = false;
    this.helpLanguage = this.selectedLanguage;
    this.getHelp();
    // this.store.dispatch(new BenefitActions.GetLanguageResources({ Id: language.Id }));
    this.store.dispatch(
      new AuthActions.SetDefaultLanguage({ defaultLanguage: language })
    );
    var req = {
      userId: parseInt(this.user.userId),
      languageId: this.selectedLanguage.Id
    };
    this.languageService.ChangeLanguage(req).subscribe(res => {
      if (res) {
        this.store.dispatch(
          new BenefitActions.GetData({ userId: this.user.userId })
        );
      }
    });
  }
}
