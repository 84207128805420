import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FlexibleRightContentComponent } from './components/flexible-right-content/flexible-right-content.component';
import { FlexibleRightComponent } from './components/flexible-right/flexible-right.component';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import { MainRoutingModule } from './main-routing.module';
import { SurveyModule } from '../survey/survey.module';


@NgModule({
  declarations: [
    DashboardComponent,
    UserSettingsComponent,
    FlexibleRightComponent,
    FlexibleRightContentComponent,
  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    TabsModule.forRoot(),
    SharedModule,
    LayoutModule,
    SurveyModule
  ]
})
export class MainModule { }
