import { Component, Inject, OnInit, VERSION, ViewChild } from "@angular/core";
import { AuthService } from "src/app/core/services/auth.service";
import { ClientService } from "src/app/core/services/client.service";
import { HomeContentResponse } from "src/models/home-content";
import { APP_CONFIG, IAppConfig } from "src/app/core/services/app.config";
import { NgbCarouselConfig, NgbCarousel } from "@ng-bootstrap/ng-bootstrap";
import { AnnouncementResponse } from "src/models/announcement";

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.scss"],
  providers: [NgbCarouselConfig],
})
export class HomePageComponent implements OnInit {
  @ViewChild("carousel", { static: true }) carousel!: NgbCarousel;

  authUser: any;
  announcements: AnnouncementResponse[] = [];
  activeSlide: number = 0;

  homeContentModel = new HomeContentResponse();
  homeContentText: string;
  imgBasicBenefits: string;
  imgFlexibleBenefits: string;
  public loading = false;
  images = [700, 800, 807].map((n) => `https://picsum.photos/id/${n}/900/500`);
  name = "Angular " + VERSION.major;

  quickAccessItems = [
    { title: 'Yan Haklar', icon: 'fa fa-gift', link: '/esnek-yan-haklar' },
    { title: 'Profilim', icon: 'fa fa-user', link: '/profile' },
    { title: 'Alışveriş Özetim', icon: 'fa fa-shopping-basket', link: '/cart' },
    { title: 'Anketler', icon: 'fa fa-list', link: '/survey' },
    // { title: 'S.S.S.', icon: 'fa fa-question-circle', link: '/faq' }
  ];

  infoCards = [
    { title: 'Yan Haklarınızı Seçin', icon: 'fa fa-check-circle', description: 'Yan hak sepetinizden ihtiyaçlarınıza uygun seçim yapın.' },
    { title: 'Yan Hak Sepetinizin Özetini Görüntüleyin', icon: 'fa fa-cart-arrow-down', description: 'Harcamalarınızı ve yan hak kullanımınızı görüntüleyin.' },
    { title: 'Geri Bildirim Verin', icon: 'fa fa-commenting', description: 'Sistemle ilgili anketlere katılın ve geri bildirim sağlayın.' }
  ];

  
  constructor(
    private authService: AuthService,
    private clientService: ClientService,
    @Inject(APP_CONFIG) public appConfig: IAppConfig,
    config: NgbCarouselConfig
  ) {
    config.interval = 2000;
    config.keyboard = true;
    config.pauseOnHover = true;
    config.showNavigationArrows = true;
    config.showNavigationIndicators = false;
    config.interval = 0;
  }



  ngOnInit() {
    this.authUser = this.authService.getAuthUser();
    this.getHomeContentByCompany();
    this.getAnnouncements();
  }

  getAnnouncements() {
    this.clientService.getAnnouncement(this.authUser.companyId).subscribe(
      (data) => {
        this.announcements = Array.isArray(data) ? data : [data]; // Gelen veriyi diziye çevir
      },
      (error) => {
        console.error("Duyuru verileri alınamadı:", error);
      }
    );
  }
  selectSlide(index: number): void {
    this.activeSlide = index; // Aktif slide'ı güncelle
    this.carousel.select(`slide-${index}`); // Slide ID'sini geçir
  }

  onSlide(event: any): void {
    const activeId = event.current; // NgbCarousel event'inden aktif ID'yi al
    this.activeSlide = parseInt(activeId.split("-")[1], 10); // ID'den index'i çekip güncelle
  }

  getHomeContentByCompany() {
    this.loading = true;
    this.clientService
      .getHomeContentByCompany(this.authUser.companyId)
      .subscribe((data) => {
        this.homeContentModel = data as HomeContentResponse;
        this.loading = false;
        this.homeContentText = this.homeContentModel
          ? this.homeContentModel.Description
          : "";
        this.imgBasicBenefits = this.homeContentModel
          ? this.homeContentModel.ImgBasicBenefits
          : "";
        this.imgFlexibleBenefits = this.homeContentModel
          ? this.homeContentModel.ImgFlexibleBenefits
          : "";
      });
  }
}
