import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { cloneDeep, flatten } from "lodash";
import { AuthService } from "src/app/core/services/auth.service";
import { BenefitState } from "src/app/state/benefit/benefit.reducer";
import { CartState } from "src/app/state/cart/cart.reducer";
import { BenefitCategoryResponse } from "src/models/benefit-category";
import * as BenefitActions from "../../../../state/benefit/benefit.actions";
import { ClientService } from "src/app/core/services/client.service";
import { TranslateService } from "@ngx-translate/core";
import { FlexibleBenefitsTextResponse } from "src/models/flexible-benefits-text";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "app-flexible-right-content",
  templateUrl: "./flexible-right-content.component.html",
  styleUrls: ["./flexible-right-content.component.scss"],
})
export class FlexibleRightContentComponent implements OnInit {
  benefits = [];
  category: BenefitCategoryResponse = null;
  allcategory: BenefitCategoryResponse = null;
  selectedHealthPackage = null;
  selectedGroup = null;
  tabs = [];
  others = [];
  selectedIndex = null;
  authUser = null;
  sliceCount = 300;
  allcategories: any[] = [];
  public loading = false;
  flexibleBenefitsTextModel = new FlexibleBenefitsTextResponse();
  flexibleBenefitsText: string;
  currentCategoryName: string;
  sanitizedDescription: SafeHtml = "";

  constructor(
    private store: Store<{ benefit: BenefitState; cart: CartState }>,
    private route: ActivatedRoute,
    private authService: AuthService,
    public location: Location,
    private router: Router,
    private clientService: ClientService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    const { categoryId } = this.route.snapshot.params;
    const user = this.authService.getAuthUser();
    this.store.dispatch(
      new BenefitActions.GetData({
        userId: user.userId,
      })
    );
    this.authUser = user;
    this.store
      .select((state) => state && state.benefit && state.benefit.data)
      .subscribe((x) => {
        const data = cloneDeep(x);
        if (data && data.benefitCategories && data.benefitCategories.length) {
          const categories: any[] = flatten(
            data.benefitCategories.map((cat) => cat.BenefitCategories)
          );
          this.category =
            categories.find(
              (category) => category.Id.toString() === categoryId
            ) || null;
          if (this.category.Description) {
            this.sanitizedDescription = this.sanitizer.bypassSecurityTrustHtml(
              this.category.Description
            );
          }
          if (this.category) {
            this.tabs = this.getTabs(this.category.Benefits);
            this.others = this.getOthers(this.category.Benefits);
            if (this.selectedIndex !== null) {
              if (this.selectedIndex === -1) {
                this.selectGroup(this.others, -1);
              } else {
                this.selectGroup(
                  [this.tabs[this.selectedIndex]],
                  this.selectedIndex
                );
              }
            } else {
              if (this.tabs && this.tabs.length) {
                this.selectGroup([this.tabs[0]], 0);
              } else if (this.others && this.others.length) {
                this.selectGroup(this.others, -1);
              }
            }
          }
        }
      });

    this.getFlexibleBenefitsTextByCompany();
    this.store
      .select((state) => state && state.benefit && state.benefit.data)
      .subscribe((data) => {
        let flexibleBenefitName =
          this.translateService.instant("flexibleBenefits");
        this.allcategories =
          (data &&
            data.benefitCategories &&
            data.benefitCategories.length &&
            data.benefitCategories.find(
              (x) => x.Name.toLowerCase() == flexibleBenefitName.toLowerCase()
            ).BenefitCategories) ||
          [];
      });
    console.log(this.allcategories);
  }

  getFlexibleBenefitsTextByCompany() {
    this.loading = true;
    this.clientService
      .getFlexibleBenefitsTextByCompany(this.authUser.companyId)
      .subscribe((data) => {
        this.flexibleBenefitsTextModel = data as FlexibleBenefitsTextResponse;
        this.loading = false;
        this.flexibleBenefitsText = this.flexibleBenefitsTextModel
          ? this.flexibleBenefitsTextModel.Description
          : "";
      });
  }

  get isHealthBenefit() {
    return this.category && this.category.Type === "health";
  }

  getTabs(benefits) {
    return benefits.filter(
      (benefit) =>
        benefit.BenefitPackages &&
        benefit.BenefitPackages.length &&
        (benefit.BenefitPackages.length > 2 ||
          (benefit.BenefitPackages.length === 2 &&
            !benefit.BenefitPackages.some((pck) => pck.Amount === 0)))
    );
  }

  getOthers(benefits) {
    return benefits.filter(
      (benefit) => this.tabs.map((tab) => tab.Id).indexOf(benefit.Id) === -1
    );
  }

  selectGroup(benefits: any[], index: number): void {
    if (!benefits || benefits.length === 0) {
      console.warn("No benefits available to select.");
      return;
    }
    this.selectedGroup = benefits;
    this.selectedIndex = index;
    this.currentCategoryName = benefits.length > 0 ? benefits[0].Name : "Diğer";
  }
}
