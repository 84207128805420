import { NgModule } from '@angular/core';
import { HomePageComponent } from './home-page/home-page.component';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { LayoutModule } from '../layout/layout.module';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { SurveyModule } from '../survey/survey.module';



@NgModule({
  declarations: [HomePageComponent],
  imports: [
    CommonModule,
    HomeRoutingModule,
    LayoutModule,
    FormsModule,
    SharedModule,
    TranslateModule,
    NgbCarouselModule,
    SurveyModule
  ]
})
export class HomeModule { }
