import { Component, OnInit } from '@angular/core';
import { SidebarService } from 'src/app/core/services/sidebar.service';

@Component({
  selector: 'app-shopping-cart-right',
  templateUrl: './shopping-cart-right.component.html',
  styleUrls: ['./shopping-cart-right.component.scss'],
})
export class ShoppingCartRightComponent implements OnInit {
  isSidebarVisible: boolean = false;

  constructor(private sidebarService: SidebarService) {}

  ngOnInit(): void {
    this.sidebarService.isSidebarVisible$.subscribe((isVisible) => {
      this.isSidebarVisible = isVisible;
    });
  }

  closeSidebar(): void {
    this.sidebarService.closeSidebar();
  }

  
}
