import { Component, OnInit } from '@angular/core';
import { SidebarService } from 'src/app/core/services/sidebar.service';

@Component({
  selector: 'app-home-page-settings',
  templateUrl: './home-page-settings.component.html',
  styleUrls: ['./home-page-settings.component.scss']
})
export class HomePageSettingsComponent implements OnInit {
  showFromSalary = false;
  isSidebarVisible: boolean = false;
  
  constructor(private sidebarService: SidebarService) {}

  ngOnInit(): void {
    this.sidebarService.isSidebarVisible$.subscribe((isVisible) => {
      this.isSidebarVisible = isVisible;
    });
  }

  closeSidebar(): void {
    this.sidebarService.closeSidebar();
  }

  
}
