import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettingsRightComponent } from '../layout/components/settings-right/settings-right.component';
import { SurveyComponent } from './survey.component';
import { AuthGuard } from 'src/app/core/services/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: SurveyComponent,
    data: {
      title: ''
    },
    children: [
      {
        path: 'survey/:id/:code',
        component: SurveyComponent,
        data: {
          title: 'Anket',
          completed: false
        },
        canActivate: [AuthGuard]
      },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SurveyRoutingModule { }
