import { Component, OnInit } from "@angular/core";
import { SidebarService } from "src/app/core/services/sidebar.service";

@Component({
  selector: "app-settings-right",
  templateUrl: "./settings-right.component.html",
  styleUrls: ["./settings-right.component.scss"],
})
export class SettingsRightComponent implements OnInit {
  showFromSalary = true;
  isSidebarVisible: boolean = false;

  constructor(private sidebarService: SidebarService) {}

  ngOnInit() {
    this.showFromSalary = !(
      localStorage.getItem("userCompleted") &&
      localStorage.getItem("userCompleted") === "True"
    );
    this.sidebarService.isSidebarVisible$.subscribe((isVisible) => {
      this.isSidebarVisible = isVisible;
    });
  }

  closeSidebar(): void {
    this.sidebarService.closeSidebar();
  }
}
