import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ShoppingCartRightComponent } from './components/shopping-cart-right/shopping-cart-right.component';
import { SettingsRightComponent } from './components/settings-right/settings-right.component';
import { HomePageSettingsComponent } from './components/home-page-settings/home-page-settings.component';
import { SurveyModule } from '../survey/survey.module';



@NgModule({
  declarations: [ShoppingCartRightComponent, SettingsRightComponent, HomePageSettingsComponent],
  imports: [
    CommonModule,
    SharedModule,
    SurveyModule
  ]
})
export class LayoutModule { }
